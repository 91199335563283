import { defineComponent,onMounted, reactive } from 'vue'
import { SET_USER } from '@/utils/constant'
import { getIdentity } from '@/utils/index' 
import { useRouter } from 'vue-router'
import { Dialog } from 'vant'
import { accountLogout, remaining } from '@/api/index'
import { useStore } from 'vuex'

import './index.less'

export default defineComponent({
    setup () {
        const store = useStore()
        const router = useRouter()

        onMounted(() => {
            _init()
            console.log(store, 'shazi.....')
        })

        const state: any = reactive({user: {}, account: 0})

        const _remaining = async () => {
            const res:any  = await remaining()
            state.account = res.data
            localStorage.setItem('M', res.data)
            console.log(res, '余额')
        }

        const _init = () => {
            let res: any = localStorage.getItem(SET_USER)
            _remaining()
            if(!res)return 
            res = JSON.parse(res)
            state.user = res.user
        }

        const _getIdentity = (key: number) => {
            return getIdentity(Number(key))
        }
        const onGo = (path: string) => {
            router.push({ path })
        }


        const _logout = async () => {
            const res = await accountLogout()
            console.log(res)
            localStorage.clear()
            router.push({ path: '/login' })
        }

        const onLogout = () => {
            Dialog.confirm({
                title: '提示',
                message: '确认退出？',
                    closeOnClickOverlay: true,
                }).then(() => {
                    _logout()
                }).catch(() => {
                    // on cancel
                    console.log('cancel')
            });
        }




        return () => (
            <div class="personal-page">
                <div class="header-box flex-box justify-content-between plr35">
                    <div>简历列表</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <ul class="list-box">
                    <li class="item-box info-item" onClick={ ()=> onGo('/upInfo') }>
                        <div class="flex-box infomation-box" >
                            <div>
                                 <img class="avatar" src={!state.user.imgUrl ? 'https://img.fanwoon.com/avatar.png' : state.user.imgUrl} />
                            </div>
                            <div class="title-info">
                                <div class="name">{state.user.name}</div>
                                <div style={{fontSize: '14Px'}}>{state.user.nacompanyNameme} {state.user.shortName} - {_getIdentity(state.user.jobGrade)}</div>
                            </div>
                        </div>
                        <div>
                            <img class="update-icon mt40" src="https://img.fanwoon.com/bi.png" />
                        </div>
                    </li>
                    <li class="item-box fixt-height" onClick={ ()=> onGo('/amount') }>
                        <div class="flex-account-box">
                            <img src='https://img.fanwoon.com/yue%402x.png' />
                            <span>可用余额：{Number(!state.account ? 0 : state.account).toFixed(2)}</span>
                        </div>
                        <div><span class='red'>充值</span><van-icon name="arrow" /></div>
                    </li>
                    <li class="item-box fixt-height" onClick={ ()=> onGo('/pwd') }>
                        <div>修改密码</div>
                        <div><van-icon name="arrow" /></div>
                    </li>
                    <li class="item-box fixt-height" onClick={ ()=> onGo('/upCompany') }>
                        <div>编辑门店信息</div>
                        <div><van-icon name="arrow" /></div>
                    </li>
                    <li class="item-box fixt-height" onClick={ ()=> onGo('/areement') }>
                        <div>用户协议</div>
                        <div><van-icon name="arrow" /></div>
                    </li>
                </ul>
                <div class="logout-box" onClick={ onLogout }>退出登录</div>
            </div>
        )
    }
})